@import 'src/styles/bootstrapVars';
@import 'bootstrap/scss/mixins/breakpoints';

.layout {
  display: flex;
  width: 100vw;
  height: 100dvh;
  margin: 0;
  padding: 0;
}

.left {
  padding: 20px 15px;
}

.right {
  background-color: var(--brand-secondary-pale);
}

@include media-breakpoint-up(md) {
  .left {
    padding: 0;
  }
}

@include media-breakpoint-up(lg) {
  .left {
    width: 37.37%;
  }

  .rightRegistration {
    width: 62.63%;
  }
}

@include media-breakpoint-up(xl) {
  .left {
    width: 43.58%;
  }

  .rightRegistration {
    width: 56.42%;
  }
}

