@use 'src/styles/colors';
@import 'src/styles/bootstrapVars';
@import 'bootstrap/scss/mixins/breakpoints';

.authForm {
  gap: 24px;
  width: 100%;
}

.banner {
  width: 47.55%;
}

@include media-breakpoint-up(md)  {
  .banner {
    width: 71.42%;
  }

  .authForm {
    width: 43.75%;
  }
}

@include media-breakpoint-up(lg)  {
  .banner {
    width: 48.22%;
  }

  .logo {
    width: 43.23%;
  }

  .authForm {
    width: 64.68%;
  }

  .right {
    margin-top: 22.65dvh;
    gap: 64px;
    height: 61.45%;
  }
}

@include media-breakpoint-up(xl)  {
  .banner {
    width: 53.54%;
  }

  .logo {
    width: 36.47%;
  }

  .authForm {
    width: 41.03%;
  }

  .right {
    margin-top: 21.85dvh;
    gap: 64px;
    height: 63.35%;
  }
}

.cooldownCounter {
  background-color: colors.$brand-secondary-pale;

  border-radius: 8px;
}
