@use "sass:map";
@use "sass:list";


$base-primary-dark: var(--base-primary-dark);
$base-additional-grey: var(--base-additional-grey);
$base-secondary-1: var(--base-secondary-1);
$base-secondary-2: var(--base-secondary-2);
$base-secondary-pale: var(--base-secondary-pale);
$base-color-white: var(--base-color-white);
$brand-additional-dark: var(--brand-additional-dark);
$brand-primary: var(--brand-primary);
$brand-secondary-1: var(--brand-secondary-1);
$brand-secondary-2: var(--brand-secondary-2);
$brand-secondary-pale: var(--brand-secondary-pale);
$blue-additional-dark: var(--blue-additional-dark);
$blue-primary: var(--blue-primary);
$blue-secondary-1: var(--blue-secondary-1);
$blue-secondary-2: var(--blue-secondary-2);
$blue-secondary-pale: var(--blue-secondary-pale);
$green-additional-dark: var(--green-additional-dark);
$green-primary: var(--green-primary);
$green-secondary-1: var(--green-secondary-1);
$green-secondary-2: var(--green-secondary-2);
$green-secondary-pale: var(--green-secondary-pale);
$red-additional-dark: var(--red-additional-dark);
$red-primary: var(--red-primary);
$red-secondary-1: var(--red-secondary-1);
$red-secondary-2: var(--red-secondary-2);
$red-secondary-pale: var(--red-secondary-pale);
$orange-additional-dark: var(--orange-additional-dark);
$orange-primary: var(--orange-primary);
$orange-secondary-1: var(--orange-secondary-1);
$orange-secondary-2: var(--orange-secondary-2);
$orange-secondary-pale: var(--orange-secondary-pale);


$colorsBase: (
        base-primary-dark: #141414,
        base-additional-grey: #505050,
        base-secondary-1: #8c8c8c,
        base-secondary-2: #d1d1d1,
        base-secondary-pale: #f0f0f0,
        base-color-white: #fff,
        brand-additional-dark: #383494,
        brand-primary: #6159ff,
        brand-secondary-1: #9984ff,
        brand-secondary-2: #ceb7ff,
        brand-secondary-pale: #f0f0ff,
        blue-additional-dark: #1277c1,
        blue-primary: #158fe8,
        blue-secondary-1: #59b9ff,
        blue-secondary-2: #a1d2f6,
        blue-secondary-pale: #ecf9ff,
        green-additional-dark: #135200,
        green-primary: #389e0d,
        green-secondary-1: #73d13d,
        green-secondary-2: #b7eb8f,
        green-secondary-pale: #f6ffed,
        red-additional-dark: #b7003d,
        red-primary: #cf133c,
        red-secondary-1: #e51241,
        red-secondary-2: #ff8095,
        red-secondary-pale: #fff0f3,
        orange-additional-dark: #ff7222,
        orange-primary: #ff8d00,
        orange-secondary-1: #ffa940,
        orange-secondary-2: #ffd591,
        orange-secondary-pale: #fff7e6,
);

$colorsKeys: map.keys($colorsBase);

@mixin colorVariables {
  @each $color, $value in $colorsBase {
    --#{$color}: #{$value};
  }
};